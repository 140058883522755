import { isNumber } from 'lodash';
import moment from 'moment';
import React from 'react';
import { css } from 'styled-components';
import { purchaseDemandApprover } from '../../../../models/entities/purchaseDemand/purchaseDemandApprover';
import { eShipmentTypes } from '../../../../models/entities/shipment/shipmentTypes';
import carriers from '../../../../static/data/carriers.json';
import countries from '../../../../static/data/countries.json';
import stations from '../../../../static/data/stations.json';
import { numberWithCommas } from '../../../../utils/patterns';
import AttributeOptionsGridView from '../../../pages/products/components/AttributeOption/AttributeOptionsGridView';
import CopyToClipboard from '../../CopyToClipboard';
import CZTooltip from '../../CZTooltip/CZTooltip';
import { ModalPreviewFileAsync } from '../../FilesPreview/ModalPreview';
import { Checkbox } from '../../inputs/base';
import MaterialPopover from '../../Popover/MaterialPopover';
import StyledLink from '../../SmallComponents/StyledLink';
import { styled } from '../../Theme/theme';
import UserBadge from '../../UserBadge/UserBadge';
import BidLink from '../specialColumns/BidLink';
import BusinessPartnerAddressMenu from '../specialColumns/BusinessPartnerAddressMenu';
import BusinessPartnerContactMenu from '../specialColumns/BusinessPartnerContactMenu';
import BusinessPartnerDocumentMenu from '../specialColumns/BusinessPartnerDocumentMenu';
import BusinessPartnerMenu from '../specialColumns/BusinessPartnerMenu';
import CompanyAddressMenu from '../specialColumns/CompanyAddressMenu';
import CompanyColor from '../specialColumns/CompanyColor';
import CompanyLink from '../specialColumns/CompanyLink';
import Country from '../specialColumns/Country';
import FileExtIcon from '../specialColumns/FileExtIcon';
import OrderDocumentMenu from '../specialColumns/OrderDocumentMenu';
import ProductAttributeMenu from '../specialColumns/ProductAttributeMenu';
import ProductAttributeOptionMenu from '../specialColumns/ProductAttributeOptionMenu';
import ProductDocumentMenu from '../specialColumns/ProductDocumentMenu';
import ProductLinkMenu from '../specialColumns/ProductLinkMenu';
import ProductMenu from '../specialColumns/ProductMenu';
import PurchaseDemandDocumentMenu from '../specialColumns/PurchaseDemandDocumentMenu';
import PurchaseDemandItemMenu from '../specialColumns/PurchaseDemandItemMenu';
import PurchaseDemandLink from '../specialColumns/PurchaseDemandLink';
import PurchaseDemandMenu from '../specialColumns/PurchaseDemandMenu';
import PurchaseOrderDocumentMenu from '../specialColumns/PurchaseOrderDocumentMenu';
import PurchaseOrderDocumentMenuSupplierUpdates from '../specialColumns/PurchaseOrderDocumentMenuSupplierUpdates';
import PurchaseOrderEventMenu from '../specialColumns/PurchaseOrderEventMenu';
import PurchaseOrderItemMenu from '../specialColumns/PurchaseOrderItemMenu';
import PurchaseOrderLink from '../specialColumns/PurchaseOrderLink';
import PurchaseOrderMenu from '../specialColumns/PurchaseOrderMenu';
import RelatedProductMenu from '../specialColumns/RelatedProductMenu';
import ShipmentAccountingMenu from '../specialColumns/shipmentAccountingMenu';
import ShipmentDocumentMenu from '../specialColumns/ShipmentDocumentMenu';
import ShipmentEventMenu from '../specialColumns/ShipmentEventMenu';
import ShipmentLink from '../specialColumns/ShipmentLink';
import ShipmentMenu from '../specialColumns/ShipmentMenu';
import ShipmentStateSelectionColumn from '../specialColumns/ShipmentStateSelectionColumn';
import ShipmentStation from '../specialColumns/ShipmentStation';
import ShipmentType from '../specialColumns/ShipmentType';
import ShipmentWarning from '../specialColumns/ShipmentWarning';
import { specialColumnType } from '../types/Column';

type Props<T> = {
    record: T;
    entityId: any;
    entityUserId: any;
    cellValue?: any;
    cellType?: specialColumnType;
    bindedData?: Array<any> | null;
    width: string;
    minWidth?: string;
    height?: number;
    textAlign?: string;
    pointer?: boolean;
    localization?: any;
    colNumber?: number;
    settings?: any;
    isLastVisited?: boolean;
    parentEntityId?: string;
    hideTitleAttribute?: boolean;
    color?: string;
    onClick?: (row: any) => void;
    onRowMoreOptionsClick?: () => void;
    valueFormatter?: (cellValue: string, bindedData?: Array<any> | null) => string;
    renderer?: (cellValue: string, bindedData?: Array<any> | null) => JSX.Element;
};

function CellRenderer<T>({
    record,
    entityId,
    cellValue,
    cellType,
    width,
    minWidth,
    height,
    entityUserId,
    textAlign,
    pointer,
    bindedData,
    localization,
    colNumber,
    settings,
    isLastVisited,
    parentEntityId,
    hideTitleAttribute,
    color,
    onClick,
    valueFormatter,
    renderer,
    onRowMoreOptionsClick
}: Props<T>) {
    if (!cellType)
        return (
            <CellWrapper
                isLastVisited={isLastVisited}
                onClick={onClick}
                width={width}
                minWidth={minWidth}
                textAlign={textAlign}
                pointer={pointer || !!onClick}
                colNumber={colNumber}
                color={color}
            >
                <CellSpan title={hideTitleAttribute ? undefined : cellValue}>
                    {renderer
                        ? renderer(cellValue === '-' ? null : cellValue, bindedData)
                        : valueFormatter
                        ? valueFormatter(cellValue, bindedData)
                        : typeof cellValue === 'object'
                        ? JSON.stringify(cellValue)
                        : cellValue}
                </CellSpan>
            </CellWrapper>
        );
    else {
        switch (cellType) {
            case 'shipmentType':
                return (
                    <CellWrapper
                        isLastVisited={isLastVisited}
                        onClick={onClick}
                        width={width}
                        minWidth={minWidth}
                        textAlign={textAlign}
                        pointer={pointer}
                        colNumber={colNumber}
                    >
                        <CZTooltip
                            text={`${eShipmentTypes[cellValue] || cellValue}${
                                bindedData && bindedData[0] && typeof bindedData[0] === 'string' && bindedData[0].trim() !== ''
                                    ? ' - ' + (bindedData && bindedData[0])
                                    : ''
                            }`}
                        >
                            <div>
                                <ShipmentType value={cellValue} />
                            </div>
                        </CZTooltip>
                    </CellWrapper>
                );
            case 'origin': {
                const originCountry = bindedData && bindedData.length > 0 && typeof bindedData[0] === 'string' ? bindedData[0] : 'IL';
                const originStationName =
                    bindedData && bindedData[1] ? bindedData[1] : stations.find((s) => s.code === cellValue && s.country === originCountry)?.location;
                const originCountryName = countries.find((item) => item.code === originCountry)?.name;

                return (
                    <CellWrapper
                        isLastVisited={isLastVisited}
                        textAlign={'space-between'}
                        onClick={onClick}
                        width={width}
                        minWidth={minWidth}
                        pointer={pointer}
                        colNumber={colNumber}
                    >
                        <CZTooltip text={(originStationName ? originStationName + ', ' : '') + originCountryName}>
                            <OriginCountryWrapper>
                                <ShipmentStation country={originCountry} port={cellValue} />
                                <RightArrowIcon />
                            </OriginCountryWrapper>
                        </CZTooltip>
                    </CellWrapper>
                );
            }
            case 'destination': {
                const destinationCountry = bindedData && bindedData.length > 0 && typeof bindedData[0] === 'string' ? bindedData[0] : 'IL';
                const destinationStationName =
                    bindedData && bindedData[1]
                        ? bindedData[1]
                        : stations.find((s) => s.code === cellValue && s.country === destinationCountry)?.location;
                const destinationCountryName = countries.find((item) => item.code === destinationCountry)?.name;

                return (
                    <CellWrapper
                        isLastVisited={isLastVisited}
                        onClick={onClick}
                        width={width}
                        minWidth={minWidth}
                        pointer={pointer}
                        colNumber={colNumber}
                    >
                        <CZTooltip text={(destinationStationName ? destinationStationName + ', ' : '') + destinationCountryName}>
                            <div>
                                <ShipmentStation country={destinationCountry} port={cellValue} />
                            </div>
                        </CZTooltip>
                    </CellWrapper>
                );
            }
            case 'shipmentLink':
                return (
                    <CellWrapper
                        isLastVisited={isLastVisited}
                        onClick={onClick}
                        textAlign={textAlign}
                        padding="6px 6px 6px 16px"
                        width={width}
                        minWidth={minWidth}
                        pointer={pointer}
                        colNumber={colNumber}
                    >
                        {bindedData && <ShipmentLink shipmentId={bindedData[0]} shipmentNumber={cellValue} />}
                    </CellWrapper>
                );
            case 'companyLink':
                return (
                    <CellWrapper
                        isLastVisited={isLastVisited}
                        onClick={onClick}
                        textAlign={textAlign}
                        padding="6px 6px 6px 16px"
                        width={width}
                        minWidth={minWidth}
                        pointer={pointer}
                        colNumber={colNumber}
                    >
                        {bindedData && <CompanyLink companyId={bindedData[0]} value={cellValue} />}
                    </CellWrapper>
                );
            case 'purchaseOrderLink':
                return (
                    <CellWrapper
                        isLastVisited={isLastVisited}
                        onClick={onClick}
                        textAlign={textAlign}
                        padding="6px 6px 6px 16px"
                        width={width}
                        minWidth={minWidth}
                        pointer={pointer}
                        colNumber={colNumber}
                    >
                        {bindedData && <PurchaseOrderLink purchaseOrderId={bindedData[0]} purchaseOrderNumber={cellValue} />}
                    </CellWrapper>
                );
            case 'purchaseDemandLink':
                return (
                    <CellWrapper
                        isLastVisited={isLastVisited}
                        onClick={onClick}
                        textAlign={textAlign}
                        padding="6px 6px 6px 16px"
                        width={width}
                        minWidth={minWidth}
                        pointer={pointer}
                        colNumber={colNumber}
                    >
                        {bindedData && <PurchaseDemandLink purchaseDemandId={bindedData[0]} purchaseDemandNumber={cellValue} />}
                    </CellWrapper>
                );
            case 'purchaseDemandApprovers':
                return (
                    <CellWrapper
                        isLastVisited={isLastVisited}
                        onClick={onClick}
                        textAlign={textAlign}
                        padding="6px 6px 6px 16px"
                        width={width}
                        minWidth={minWidth}
                        pointer={pointer}
                        colNumber={colNumber}
                    >
                        {cellValue.length ? (
                            <MaterialPopover
                                anchor={
                                    <>
                                        <span style={{ marginRight: '10px' }}>{cellValue.length}</span>
                                        <span className="material-icons">info</span>
                                    </>
                                }
                                anchorStyle={{ display: 'flex', alignItems: 'center' }}
                            >
                                <div>
                                    {cellValue?.map((item: purchaseDemandApprover) => (
                                        <div style={{ display: 'flex', maxWidth: '150px', minWidth: '150px', marginBottom: '5px' }}>
                                            <UserBadge flexRow width={25} userId={item.userId} />
                                        </div>
                                    ))}
                                </div>
                            </MaterialPopover>
                        ) : (
                            <CellSpan>-</CellSpan>
                        )}
                    </CellWrapper>
                );
            case 'bidLink':
                return (
                    <CellWrapper
                        isLastVisited={isLastVisited}
                        onClick={onClick}
                        textAlign={textAlign}
                        padding="6px 6px 6px 16px"
                        width={width}
                        minWidth={minWidth}
                        pointer={pointer}
                        colNumber={colNumber}
                    >
                        {bindedData && <BidLink bidId={bindedData[0]} bidNumber={cellValue} />}
                    </CellWrapper>
                );
            case 'fakeLink':
                return (
                    <CellWrapper
                        isLastVisited={isLastVisited}
                        onClick={onClick}
                        textAlign={textAlign}
                        padding="6px 6px 6px 16px"
                        width={width}
                        minWidth={minWidth}
                        pointer={pointer}
                        colNumber={colNumber}
                    >
                        <StyledLink to={{ pathname: undefined }}>{cellValue}</StyledLink>
                    </CellWrapper>
                );
            case 'link':
                return (
                    <CellWrapper
                        isLastVisited={isLastVisited}
                        onClick={onClick}
                        textAlign={textAlign}
                        padding="6px 6px 6px 16px"
                        width={width}
                        minWidth={minWidth}
                        pointer={pointer}
                        colNumber={colNumber}
                    >
                        {cellValue && cellValue !== '-' ? (
                            <StyledLink to={{ pathname: cellValue }} target="_blank">
                                {cellValue}
                            </StyledLink>
                        ) : (
                            <CellSpan>-</CellSpan>
                        )}
                    </CellWrapper>
                );
            case 'shipmentMenu':
                return (
                    <CellWrapper
                        isLastVisited={isLastVisited}
                        onClick={onClick}
                        width={width}
                        minWidth={minWidth}
                        pointer={pointer}
                        padding="0px"
                        colNumber={colNumber}
                    >
                        <ShipmentMenu
                            shipment={record as any}
                            entityId={entityId}
                            entityData={{ CargoZoneNumber: bindedData && bindedData[0], companyId: bindedData && bindedData[1] }}
                        />
                    </CellWrapper>
                );
            case 'shipmentEventMenu':
                return (
                    <CellWrapper
                        isLastVisited={isLastVisited}
                        onClick={onClick}
                        width={width}
                        minWidth={minWidth}
                        pointer={pointer}
                        padding="0px"
                        colNumber={colNumber}
                    >
                        <ShipmentEventMenu
                            entityId={entityId}
                            userId={entityUserId}
                            localization={localization}
                            onRowMoreOptionsClick={onRowMoreOptionsClick}
                        />
                    </CellWrapper>
                );
            case 'shipmentAccountingMenu':
                return (
                    <CellWrapper
                        isLastVisited={isLastVisited}
                        onClick={onClick}
                        width={width}
                        minWidth={minWidth}
                        pointer={pointer}
                        padding="0px"
                        colNumber={colNumber}
                    >
                        <ShipmentAccountingMenu
                            entityId={entityId}
                            userId={entityUserId}
                            localization={localization}
                            onRowMoreOptionsClick={onRowMoreOptionsClick}
                        />
                    </CellWrapper>
                );
            case 'shipmentDocumentMenu':
                return (
                    <CellWrapper
                        isLastVisited={isLastVisited}
                        onClick={onClick}
                        width={width}
                        minWidth={minWidth}
                        pointer={pointer}
                        padding="0px"
                        colNumber={colNumber}
                    >
                        <ShipmentDocumentMenu
                            entityId={entityId}
                            userId={entityUserId}
                            localization={localization}
                            onRowMoreOptionsClick={onRowMoreOptionsClick}
                        />
                    </CellWrapper>
                );
            case 'productMenu':
                return (
                    <CellWrapper
                        isLastVisited={isLastVisited}
                        onClick={onClick}
                        width={width}
                        minWidth={minWidth}
                        pointer={pointer}
                        padding="0px"
                        colNumber={colNumber}
                    >
                        <ProductMenu entityId={entityId} localization={localization} onRowMoreOptionsClick={onRowMoreOptionsClick} />
                    </CellWrapper>
                );
            case 'productDocumentMenu':
                return (
                    <CellWrapper
                        isLastVisited={isLastVisited}
                        onClick={onClick}
                        width={width}
                        minWidth={minWidth}
                        pointer={pointer}
                        padding="0px"
                        colNumber={colNumber}
                    >
                        <ProductDocumentMenu
                            entityId={entityId}
                            parentEntityId={parentEntityId}
                            localization={localization}
                            onRowMoreOptionsClick={onRowMoreOptionsClick}
                        />
                    </CellWrapper>
                );
            case 'orderDocumentMenu':
                return (
                    <CellWrapper
                        isLastVisited={isLastVisited}
                        onClick={onClick}
                        width={width}
                        minWidth={minWidth}
                        pointer={pointer}
                        padding="0px"
                        colNumber={colNumber}
                    >
                        <OrderDocumentMenu
                            entityId={entityId}
                            parentEntityId={parentEntityId}
                            localization={localization}
                            onRowMoreOptionsClick={onRowMoreOptionsClick}
                        />
                    </CellWrapper>
                );
            case 'productAttributeMenu':
                return (
                    <CellWrapper
                        isLastVisited={isLastVisited}
                        onClick={onClick}
                        width={width}
                        minWidth={minWidth}
                        pointer={pointer}
                        padding="0px"
                        colNumber={colNumber}
                    >
                        <ProductAttributeMenu
                            entityId={entityId}
                            parentEntityId={parentEntityId}
                            localization={localization}
                            onRowMoreOptionsClick={onRowMoreOptionsClick}
                        />
                    </CellWrapper>
                );
            case 'productLinkMenu':
                return (
                    <CellWrapper
                        isLastVisited={isLastVisited}
                        onClick={onClick}
                        width={width}
                        minWidth={minWidth}
                        pointer={pointer}
                        padding="0px"
                        colNumber={colNumber}
                    >
                        <ProductLinkMenu
                            entityId={entityId}
                            parentEntityId={parentEntityId}
                            localization={localization}
                            onRowMoreOptionsClick={onRowMoreOptionsClick}
                        />
                    </CellWrapper>
                );
            case 'relatedProductMenu':
                return (
                    <CellWrapper
                        isLastVisited={isLastVisited}
                        onClick={onClick}
                        width={width}
                        minWidth={minWidth}
                        pointer={pointer}
                        padding="0px"
                        colNumber={colNumber}
                    >
                        <RelatedProductMenu entityId={entityId} localization={localization} />
                    </CellWrapper>
                );
            case 'productAttributeOptionMenu':
                return (
                    <CellWrapper
                        isLastVisited={isLastVisited}
                        onClick={onClick}
                        width={width}
                        minWidth={minWidth}
                        pointer={pointer}
                        padding="0px"
                        colNumber={colNumber}
                    >
                        <ProductAttributeOptionMenu
                            entityId={entityId}
                            parentEntityId={parentEntityId}
                            localization={localization}
                            onRowMoreOptionsClick={onRowMoreOptionsClick}
                        />
                    </CellWrapper>
                );

            case 'attributeOption':
                return (
                    <CellWrapper
                        isLastVisited={isLastVisited}
                        onClick={onClick}
                        width={width}
                        minWidth={minWidth}
                        pointer={pointer}
                        padding="0px"
                        colNumber={colNumber}
                    >
                        <AttributeOptionsGridView attributeOptions={cellValue} attributeId={entityId} productId={parentEntityId} />
                    </CellWrapper>
                );
            case 'businessPartnerMenu':
                return (
                    <CellWrapper
                        isLastVisited={isLastVisited}
                        onClick={onClick}
                        width={width}
                        minWidth={minWidth}
                        pointer={pointer}
                        padding="0px"
                        colNumber={colNumber}
                    >
                        <BusinessPartnerMenu entityId={entityId} localization={localization} onRowMoreOptionsClick={onRowMoreOptionsClick} />
                    </CellWrapper>
                );
            case 'businessPartnerDocumentMenu':
                return (
                    <CellWrapper
                        isLastVisited={isLastVisited}
                        onClick={onClick}
                        width={width}
                        minWidth={minWidth}
                        pointer={pointer}
                        padding="0px"
                        colNumber={colNumber}
                    >
                        <BusinessPartnerDocumentMenu entityId={entityId} localization={localization} onRowMoreOptionsClick={onRowMoreOptionsClick} />
                    </CellWrapper>
                );
            case 'businessPartnerContactMenu':
                return (
                    <CellWrapper
                        isLastVisited={isLastVisited}
                        onClick={onClick}
                        width={width}
                        minWidth={minWidth}
                        pointer={pointer}
                        padding="0px"
                        colNumber={colNumber}
                    >
                        <BusinessPartnerContactMenu entityId={entityId} localization={localization} onRowMoreOptionsClick={onRowMoreOptionsClick} />
                    </CellWrapper>
                );
            case 'businessPartnerAddressMenu':
                return (
                    <CellWrapper
                        isLastVisited={isLastVisited}
                        onClick={onClick}
                        width={width}
                        minWidth={minWidth}
                        pointer={pointer}
                        padding="0px"
                        colNumber={colNumber}
                    >
                        <BusinessPartnerAddressMenu entityId={entityId} localization={localization} onRowMoreOptionsClick={onRowMoreOptionsClick} />
                    </CellWrapper>
                );
            case 'companyAddressMenu':
                return (
                    <CellWrapper
                        isLastVisited={isLastVisited}
                        onClick={onClick}
                        width={width}
                        minWidth={minWidth}
                        pointer={pointer}
                        padding="0px"
                        colNumber={colNumber}
                    >
                        <CompanyAddressMenu entityId={entityId} localization={localization} onRowMoreOptionsClick={onRowMoreOptionsClick} />
                    </CellWrapper>
                );
            case 'fileExtIcon': {
                const fileId = bindedData && bindedData[0];
                if (!fileId)
                    return (
                        <CellWrapper width={width} minWidth={minWidth} pointer={pointer} colNumber={colNumber}>
                            -
                        </CellWrapper>
                    );
                return (
                    <CellWrapper width={width} minWidth={minWidth} pointer={pointer} colNumber={colNumber}>
                        <ModalPreviewFileAsync
                            triggerChildren={<FileExtIcon filename={cellValue} />}
                            fileId={fileId}
                            fileName={bindedData && bindedData[1]}
                        />
                    </CellWrapper>
                );
            }
            case 'date':
                return (
                    <CellWrapper
                        isLastVisited={isLastVisited}
                        onClick={onClick}
                        width={width}
                        minWidth={minWidth}
                        textAlign={textAlign}
                        pointer={pointer}
                        colNumber={colNumber}
                        color={color}
                    >
                        <CellSpan title={hideTitleAttribute ? undefined : cellValue}>
                            {cellValue && (moment(cellValue).isValid() ? moment(cellValue).format('DD/MM/YY') : '-')}
                        </CellSpan>
                    </CellWrapper>
                );
            case 'date-time':
                return (
                    <CellWrapper
                        isLastVisited={isLastVisited}
                        onClick={onClick}
                        width={width}
                        minWidth={minWidth}
                        textAlign={textAlign}
                        pointer={pointer}
                        colNumber={colNumber}
                        color={color}
                    >
                        <CellSpan title={hideTitleAttribute ? undefined : cellValue}>
                            {cellValue && moment(cellValue).format('DD/MM/YYYY HH:mm')}
                        </CellSpan>
                    </CellWrapper>
                );
            case 'bool': {
                return (
                    <CellWrapper
                        isLastVisited={isLastVisited}
                        onClick={onClick}
                        width={width}
                        minWidth={minWidth}
                        textAlign={textAlign}
                        pointer={pointer}
                        colNumber={colNumber}
                        color={color}
                    >
                        <CellSpan title={'' + cellValue}>
                            <Checkbox checked={cellValue === true} />
                        </CellSpan>
                    </CellWrapper>
                );
            }
            case 'event_name':
                if (bindedData) {
                    const eventType = settings?.eventTypes?.find((item: any) => item.code === bindedData[0]);
                    return (
                        <CellWrapper
                            isLastVisited={isLastVisited}
                            onClick={onClick}
                            width={width}
                            minWidth={minWidth}
                            textAlign={textAlign}
                            pointer={pointer}
                            colNumber={colNumber}
                        >
                            <CellSpan title={hideTitleAttribute ? undefined : cellValue}>{eventType?.name || bindedData[0]}</CellSpan>
                        </CellWrapper>
                    );
                }
                break;
            case 'event_description':
                if (bindedData) {
                    const eventType = settings?.eventTypes?.find((item: any) => item.code === bindedData[0]);
                    return (
                        <CellWrapper
                            isLastVisited={isLastVisited}
                            onClick={onClick}
                            width={width}
                            minWidth={minWidth}
                            textAlign={textAlign}
                            pointer={pointer}
                            colNumber={colNumber}
                        >
                            <CellSpan title={hideTitleAttribute ? undefined : cellValue}>
                                {bindedData[1] || eventType?.description || bindedData[0]}
                            </CellSpan>
                        </CellWrapper>
                    );
                }
                break;
            case 'party_name':
                if (bindedData) {
                    const partyType = settings?.partyTypes?.find((item: any) => item.code === bindedData[0]);
                    return (
                        <CellWrapper
                            isLastVisited={isLastVisited}
                            onClick={onClick}
                            width={width}
                            minWidth={minWidth}
                            textAlign={textAlign}
                            pointer={pointer}
                            colNumber={colNumber}
                        >
                            <CellSpan title={hideTitleAttribute ? undefined : cellValue}>{partyType?.name || bindedData[0]}</CellSpan>
                        </CellWrapper>
                    );
                }
                break;
            case 'state_name':
                if (bindedData) {
                    const stateType = settings?.state?.find((item: any) => item.code === bindedData[0]);
                    return (
                        <CellWrapper
                            isLastVisited={isLastVisited}
                            onClick={onClick}
                            width={width}
                            minWidth={minWidth}
                            textAlign={textAlign}
                            pointer={pointer}
                            colNumber={colNumber}
                        >
                            <CellSpan title={hideTitleAttribute ? undefined : cellValue}>{stateType?.name || bindedData[0]}</CellSpan>
                        </CellWrapper>
                    );
                }
                break;
            case 'shipment_state_name':
                if (bindedData) {
                    const stateType = settings?.state?.find((item: any) => item.code === bindedData[0]);
                    return (
                        <CellWrapper
                            isLastVisited={isLastVisited}
                            onClick={onClick}
                            width={width}
                            minWidth={minWidth}
                            textAlign={textAlign}
                            pointer={pointer}
                            colNumber={colNumber}
                        >
                            <ShipmentStateSelectionColumn shipment={record as any} shipmentState={stateType.code}></ShipmentStateSelectionColumn>
                        </CellWrapper>
                    );
                }
                break;
            case 'document_type':
                if (bindedData) {
                    let documentType = settings?.documentTypes?.find((item: any) => item.code === bindedData[0])?.name;
                    if (Array.isArray(bindedData[0])) {
                        documentType = '';
                        bindedData[0].forEach((type, index) => {
                            const name = settings?.documentTypes?.find((item: any) => item.code === type)?.name;
                            if (name) {
                                documentType += name;
                                if (index !== bindedData[0].length - 1) documentType += ',';
                            }
                        });
                    }
                    return (
                        <CellWrapper
                            isLastVisited={isLastVisited}
                            onClick={onClick}
                            width={width}
                            minWidth={minWidth}
                            textAlign={textAlign}
                            pointer={pointer}
                            colNumber={colNumber}
                        >
                            <CellSpan title={hideTitleAttribute ? undefined : cellValue}>{documentType || bindedData[0]}</CellSpan>
                        </CellWrapper>
                    );
                }
                break;

            case 'dimensions':
                return (
                    <CellWrapper
                        isLastVisited={isLastVisited}
                        textAlign={'space-between'}
                        onClick={onClick}
                        width={width}
                        minWidth={minWidth}
                        pointer={pointer}
                        colNumber={colNumber}
                    >
                        {bindedData && bindedData[0] && bindedData[1] && bindedData[2] ? (
                            <CellSpan>{`${bindedData[0]}*${bindedData[1]}*${bindedData[2]} ${bindedData[3]}`}</CellSpan>
                        ) : (
                            <CellSpan>-</CellSpan>
                        )}
                    </CellWrapper>
                );
            case 'weight':
            case 'volume':
                return (
                    <CellWrapper
                        isLastVisited={isLastVisited}
                        textAlign={'space-between'}
                        onClick={onClick}
                        width={width}
                        minWidth={minWidth}
                        pointer={pointer}
                        colNumber={colNumber}
                    >
                        {bindedData && bindedData[0] ? <CellSpan>{`${bindedData[0]} ${bindedData[1]}`}</CellSpan> : <CellSpan>-</CellSpan>}
                    </CellWrapper>
                );

            case 'weight_volume':
                if (!bindedData) return null;
                const weight = (bindedData[0] || '') + (bindedData[1] || '');
                const volume = (bindedData[2] || '') + (bindedData[3] || '');
                return (
                    <CellWrapper
                        isLastVisited={isLastVisited}
                        textAlign={'space-between'}
                        onClick={onClick}
                        width={width}
                        minWidth={minWidth}
                        pointer={pointer}
                        colNumber={colNumber}
                        color={color}
                    >
                        {bindedData && (
                            <CellSpan>
                                {(volume ? weight + '/' : weight) || '-'}
                                {volume}
                            </CellSpan>
                        )}
                    </CellWrapper>
                );

            case 'shipmentAlertStatus':
                return (
                    <CellWrapper
                        isLastVisited={isLastVisited}
                        textAlign={'center'}
                        onClick={onClick}
                        width={width}
                        minWidth={minWidth}
                        pointer={pointer}
                        colNumber={colNumber}
                    >
                        <ShipmentWarning alertStatus={cellValue} shipmentId={bindedData && bindedData[0]} />
                    </CellWrapper>
                );
            case 'carrier': {
                const carrier = carriers.find((item) => item.carrierCode === cellValue);
                return (
                    <CellWrapper
                        isLastVisited={isLastVisited}
                        onClick={onClick}
                        width={width}
                        minWidth={minWidth}
                        pointer={pointer}
                        colNumber={colNumber}
                    >
                        <CellSpan>{carrier?.carrierName || cellValue || '-'}</CellSpan>
                    </CellWrapper>
                );
            }

            case 'purchaseOrderMenu':
                return (
                    <CellWrapper
                        isLastVisited={isLastVisited}
                        onClick={onClick}
                        width={width}
                        minWidth={minWidth}
                        pointer={pointer}
                        padding="0px"
                        colNumber={colNumber}
                    >
                        <PurchaseOrderMenu
                            purchaseOrder={record as any}
                            entityId={entityId}
                            entityData={{ CargoZoneNumber: bindedData && bindedData[0] }}
                        />
                    </CellWrapper>
                );
            case 'purchaseOrderEventMenu':
                return (
                    <CellWrapper
                        isLastVisited={isLastVisited}
                        onClick={onClick}
                        width={width}
                        minWidth={minWidth}
                        pointer={pointer}
                        padding="0px"
                        colNumber={colNumber}
                    >
                        <PurchaseOrderEventMenu entityId={entityId} localization={localization} onRowMoreOptionsClick={onRowMoreOptionsClick} />
                    </CellWrapper>
                );
            case 'purchaseOrderItemMenu':
                return (
                    <CellWrapper
                        isLastVisited={isLastVisited}
                        onClick={onClick}
                        width={width}
                        minWidth={minWidth}
                        pointer={pointer}
                        padding="0px"
                        colNumber={colNumber}
                    >
                        <PurchaseOrderItemMenu entityId={entityId} localization={localization} onRowMoreOptionsClick={onRowMoreOptionsClick} />
                    </CellWrapper>
                );
            case 'purchaseOrderDocumentMenu':
                return (
                    <CellWrapper
                        isLastVisited={isLastVisited}
                        onClick={onClick}
                        width={width}
                        minWidth={minWidth}
                        pointer={pointer}
                        padding="0px"
                        colNumber={colNumber}
                    >
                        <PurchaseOrderDocumentMenu entityId={entityId} localization={localization} onRowMoreOptionsClick={onRowMoreOptionsClick} />
                    </CellWrapper>
                );
            case 'purchaseOrderDocumentMenuSupplierUpdates':
                return (
                    <CellWrapper
                        isLastVisited={isLastVisited}
                        onClick={onClick}
                        width={width}
                        minWidth={minWidth}
                        pointer={pointer}
                        padding="0px"
                        colNumber={colNumber}
                    >
                        <PurchaseOrderDocumentMenuSupplierUpdates
                            entityId={entityId}
                            localization={localization}
                            onRowMoreOptionsClick={onRowMoreOptionsClick}
                        />
                    </CellWrapper>
                );

            case 'purchaseDemandMenu':
                return (
                    <CellWrapper
                        isLastVisited={isLastVisited}
                        onClick={onClick}
                        width={width}
                        minWidth={minWidth}
                        pointer={pointer}
                        padding="0px"
                        colNumber={colNumber}
                    >
                        <PurchaseDemandMenu entityId={entityId} />
                    </CellWrapper>
                );

            case 'purchaseDemandItemMenu':
                return (
                    <CellWrapper
                        isLastVisited={isLastVisited}
                        onClick={onClick}
                        width={width}
                        minWidth={minWidth}
                        pointer={pointer}
                        padding="0px"
                        colNumber={colNumber}
                    >
                        <PurchaseDemandItemMenu entityId={entityId} localization={localization} onRowMoreOptionsClick={onRowMoreOptionsClick} />
                    </CellWrapper>
                );
            case 'purchaseDemandDocumentMenu':
                return (
                    <CellWrapper
                        isLastVisited={isLastVisited}
                        onClick={onClick}
                        width={width}
                        minWidth={minWidth}
                        pointer={pointer}
                        padding="0px"
                        colNumber={colNumber}
                    >
                        <PurchaseDemandDocumentMenu entityId={entityId} localization={localization} onRowMoreOptionsClick={onRowMoreOptionsClick} />
                    </CellWrapper>
                );
            case 'country': {
                const country = cellValue;
                const countryName = countries.find((item) => item.code === country)?.name;

                return (
                    <CellWrapper
                        isLastVisited={isLastVisited}
                        onClick={onClick}
                        width={width}
                        minWidth={minWidth}
                        pointer={pointer}
                        colNumber={colNumber}
                    >
                        <Country value={country} text={countryName} />
                    </CellWrapper>
                );
            }
            case 'port': {
                const port = stations.find((item) => item.code === cellValue);

                return (
                    <CellWrapper
                        isLastVisited={isLastVisited}
                        onClick={onClick}
                        width={width}
                        minWidth={minWidth}
                        pointer={pointer}
                        colNumber={colNumber}
                    >
                        <CellSpan>{port?.name || port?.location || cellValue}</CellSpan>
                    </CellWrapper>
                );
            }
            case 'numberWithCommas': {
                const number = numberWithCommas(cellValue);
                return (
                    <CellWrapper
                        isLastVisited={isLastVisited}
                        onClick={onClick}
                        width={width}
                        minWidth={minWidth}
                        textAlign={textAlign}
                        pointer={pointer}
                        colNumber={colNumber}
                    >
                        <CellSpan title={number}>{number}</CellSpan>
                    </CellWrapper>
                );
            }
            case 'copyCell': {
                return (
                    <CellWrapper
                        isLastVisited={isLastVisited}
                        onClick={onClick}
                        width={width}
                        minWidth={minWidth}
                        textAlign={textAlign}
                        pointer={pointer}
                        colNumber={colNumber}
                    >
                        <CellSpan>
                            <CopyToClipboard value={cellValue} />
                        </CellSpan>
                    </CellWrapper>
                );
            }
            case 'currency_input': {
                return (
                    <CellWrapper
                        isLastVisited={isLastVisited}
                        onClick={onClick}
                        width={width}
                        minWidth={minWidth}
                        textAlign={textAlign}
                        pointer={pointer}
                        colNumber={colNumber}
                    >
                        <CellSpan>
                            {cellValue} {bindedData && bindedData[0]}
                        </CellSpan>
                    </CellWrapper>
                );
            }
            case 'transportation_cost': {
                return (
                    <CellWrapper
                        isLastVisited={isLastVisited}
                        onClick={onClick}
                        width={width}
                        minWidth={minWidth}
                        textAlign={textAlign}
                        pointer={pointer}
                        colNumber={colNumber}
                    >
                        <CellSpan>{cellValue && isNumber(cellValue) ? `${(Number(cellValue) * 100).toFixed(2)}%` : '-'}</CellSpan>
                    </CellWrapper>
                );
            }
            case 'company': {
                return (
                    <CellWrapper
                        isLastVisited={isLastVisited}
                        onClick={onClick}
                        width={width}
                        minWidth={minWidth}
                        textAlign={textAlign}
                        pointer={pointer}
                        colNumber={colNumber}
                        color={color}
                    >
                        <CompanyColor companyId={bindedData ? bindedData[0] : ''} companyName={cellValue || '-'} />
                    </CellWrapper>
                );
            }
        }
    }
    return <span>"X"</span>;
}

type StyledCell = {
    width: string;
    minWidth?: string;
    padding?: string;
    textAlign?: string;
    pointer?: boolean;
    colNumber?: number;
    isLastVisited?: boolean;
    color?: string;
};

export const CellWrapper = styled.div<StyledCell>`
    box-sizing: border-box;
    padding: ${(props) => {
        return props.padding || '6px';
    }};
    min-width: ${(props) => (props.width !== '100%' ? props.width : props.minWidth ?? '80px')};
    width: ${(props) => props.width};
    cursor: ${(props) => (props.pointer ? 'pointer' : 'default')};
    color: ${(props) => props.theme.colors.gridCell};
    justify-content: ${(props) => (props.textAlign ? props.textAlign : 'left')};
    font-size: ${(props) => props.theme.fontSizes.gridCell};
    display: flex;
    align-items: center;
    border-bottom: solid 1px #e0e0e0;
    ${(props) =>
        props.color
            ? css`
                  background: ${props.color};
              `
            : css``}
    ${(props) => props.isLastVisited && 'background:#f2f2f2;'}
    ${(props) => (props.colNumber === -1 || props.colNumber === 0) && 'z-index: 1;'}
`;

const CellSpan = styled.span`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
`;

const OriginCountryWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 75px;
`;

export const RightArrowIcon = styled.div`
    width: 20px;
    height: 20px;
    margin-left: -15px;

    background: #d1d1d1;
    height: 1px;
    width: 15px;
    position: relative;

    &:before,
    &:after {
        content: '';
        background: #d1d1d1;
        position: absolute;
        height: 1px;
        width: 8px;
    }

    &:before {
        right: -4px;
        bottom: -2px;
        transform: rotate(-30deg);
    }

    &:after {
        right: -4px;
        top: -2px;
        transform: rotate(30deg);
    }
`;

const genericMemo: <T>(component: T) => T = React.memo;

export default genericMemo(CellRenderer);
