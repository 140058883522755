import React, { useState } from 'react';
import { connect } from 'react-redux';
import { featureFlagsModel } from '../../../../models/entities/businessPartner/settings';
import claimsType from '../../../../models/entities/claimsType';
import { settings, shipment, shipmentSettingsFunc } from '../../../../models/entities/shipment/shipment';
import { localizationSelectors } from '../../../../state/ducks/localization/selectors';
import { shipmentsSelectors } from '../../../../state/ducks/shipments/selectors';
import { userSelectors } from '../../../../state/ducks/user/selectors';
import { RootState } from '../../../../state/store/store';
import { claimsDomainsType } from '../../../../userClaims';
import ChooseForwarder, { stateProps } from '../../ChooseForwarder';
import { chooseForwarderHandler } from '../../ChooseForwarder/utils';
import { DropDown } from '../../inputs/base';
import renderStateOption from '../../SmallComponents/StateOption';
type Props = {
    shipment: shipment;
    updateShipmentState: (shipmentId: string, shipmentState: string) => Promise<void>;
    isFreelancerOrForwarder?: boolean;
    settings: shipmentSettingsFunc;
    featureFlags?: featureFlagsModel;
    localizations?: any;
    shipmentState?: string;
    getUserClaims: (domain: claimsDomainsType) => claimsType;
};

const ShipmentStateSelection = ({
    shipment,
    updateShipmentState,
    isFreelancerOrForwarder,
    settings,
    featureFlags,
    localizations,
    getUserClaims,
    shipmentState
}: Props) => {
    const [chooseForwarder, setChooseForwarder] = useState<stateProps>();
    const [settingsState] = useState<settings>(settings(shipment));
    return (
        <>
            {chooseForwarder && (
                <ChooseForwarder
                    companyId={chooseForwarder.companyId}
                    onSubmit={chooseForwarder.func}
                    submitArgs={chooseForwarder.args}
                    closeModal={() => setChooseForwarder(undefined)}
                />
            )}
            <DropDown
                placeHolder={localizations.shipment_state}
                options={
                    settingsState?.state
                        ?.sort((a, b) => a.displayOrder - b.displayOrder)
                        .map((item) => ({
                            text: item.name,
                            value: item.code,
                            color: item.color
                        })) || []
                }
                value={shipmentState}
                optionRenderer={renderStateOption}
                onChange={async (value: string) => {
                    await chooseForwarderHandler(shipment, value, setChooseForwarder, updateShipmentState, isFreelancerOrForwarder, settingsState);
                }}
                disabled={featureFlags?.UNDER_FORWARDER || !getUserClaims('shipment').edit_state}
                verifyChange={{
                    title: (value: string) => `${localizations.you_are_moving_the_shipment_state_to} ${
                        settingsState?.state?.find((item) => item.code === value)?.name
                    }.
                                    ${localizations.are_you_sure}`
                }}
            />
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    isFreelancerOrForwarder: userSelectors.isFreelancerOrForwarder(state),
    featureFlags: userSelectors.featureFlags(state),
    localizations: localizationSelectors.singleShipmentPage(state),
    settings: shipmentsSelectors.settingsByLanguage(state),
    getUserClaims: (domain: claimsDomainsType) => userSelectors.getUserClaims(state)(domain)
});

const mapDispatchToProps = (dispatch: any) => ({
    updateShipmentState: (shipmentId: string, shipmentState: string, forwarderId?: string) =>
        dispatch.shipments.updateShipmentState({ shipmentId, shipmentState, forwarderId })
});

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentStateSelection);
